<template>
  <main class="evilb0t-splash-main-page">
    <div class="evilb0t-container">
      <div class="evilb0t-himself">
        <img
          class="evilb0t-splash-logo"
          alt="3vilb0t logo"
          src="./assets/logo.png"
        />
        <h3 class="evilb0t-splash-header">3vilb0t</h3>
        <span class="evilbot-splash-subheader">Just another bot!</span>
      </div>
      <div class="evilb0t-availability">
        <div class="evilb0t-availability-twitch">
          <div class="evilb0t-availability-twitch-icon">
            <a href="https://twitch.tv">
              <img src="./assets/twitch.png" alt="Discord" />
            </a>
          </div>
          <div class="evilb0t-availability-twitch-status">
            <span title="Work In Progress!" class="status"> WIP </span>
          </div>
        </div>
        <div class="evilb0t-availability-discord">
          <div class="evilb0t-availability-discord-icon">
            <a href="https://discord.com/">
              <img src="./assets/discord.png" alt="Discord" />
            </a>
          </div>
          <div class="evilb0t-availability-discord-status">
            <span title="Work In Progress!" class="status"> WIP </span>
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<script lang="ts">
import { defineComponent } from "vue";
// import TwitchLogo from "./assets/twitch.svg";
// import DiscordLogo from "./assets/discord.svg";

export default defineComponent({
  name: "3vilb0t",
  components: {
    // TwitchLogo,
    // DiscordLogo,
  },
});
</script>

<style lang="scss">
</style>
