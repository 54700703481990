
import { defineComponent } from "vue";
// import TwitchLogo from "./assets/twitch.svg";
// import DiscordLogo from "./assets/discord.svg";

export default defineComponent({
  name: "3vilb0t",
  components: {
    // TwitchLogo,
    // DiscordLogo,
  },
});
